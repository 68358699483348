<template>
  <form @submit.prevent="submitForm" novalidate>
    <div class="centerCenter" style="margin-top: 15px">
      <ion-item mode="md">
        <ion-label position="floating" class="labelText"
          >Nombre y apellido</ion-label
        >
        <ion-input type="text" name="nombre" v-model="vv.nombre.$model" />
      </ion-item>
    </div>
    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
          class="error-msg"
          v-if="vv?.nombre?.$errors[0]?.$message === 'Value is required'"
        >
          Nombre requerido
        </p>
      </div>
    </div>
      
    <div class="centerCenter" style="margin-top: 15px">
      <ion-item mode="md">
        <ion-label position="floating" class="labelText">Email</ion-label>
        <ion-input
          type="email"
          name="emailAddress"
          v-model="vv.emailAddress.$model"
        />
      </ion-item>
    </div>

    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
          class="error-msg"
          v-if="vv?.emailAddress?.$errors[0]?.$message === 'Value is required'"
        >
          Email requerido
        </p>
      </div>
    </div>

    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
          class="error-msg"
          v-if="
            vv?.emailAddress?.$errors[0]?.$message ===
            'Value is not a valid email address'
          "
        >
          Dirección de email invalida. email@ejemplo.com
        </p>
      </div>
    </div>

    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
          class="error-msg"
          v-if="errorBeforeArroba"
        >
          Dirección de email muy corta.
        </p>
      </div>
    </div>
      
<!--    <div class="centerCenter" style="margin-top: 15px">-->
<!--      <ion-item mode="md">-->
<!--        <ion-label position="floating" class="labelText"-->
<!--          >Confirmar Email</ion-label-->
<!--        >-->
<!--        <ion-input-->
<!--          type="email"-->
<!--          name="emailConfirm"-->
<!--          v-model="vv.emailConfirm.$model"-->
<!--        />-->
<!--      </ion-item>-->
<!--    </div>-->

<!--    <div class="centerCenter">-->
<!--      <div class="contenedor" style="width: 100%; justify-content: left;">-->
<!--        <p-->
<!--          class="error-msg"-->
<!--          v-if="vv?.emailConfirm?.$errors[0]?.$message === 'Value is required'"-->
<!--        >-->
<!--          Email requerido-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="centerCenter">-->
<!--      <div class="contenedor" style="width: 100%; justify-content: left;">-->
<!--        <p-->
<!--          class="error-msg"-->
<!--          v-if="vv.emailConfirm.$model !== vv.emailAddress.$model"-->
<!--        >-->
<!--          Las direcciones de email deben ser iguales-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
      
    <div class="centerCenter" style="margin-top: 15px">
      <ion-item mode="md">
        <ion-label position="floating" class="labelText">Contraseña</ion-label>
        <ion-input
          :type="passwordFieldType"
          name="password"
          v-model="vv.password.$model"
        />
        <ion-icon
          slot="end"
          :icon="passwordEyeStyle"
          @click="switchPasswordVisibility"
          style="margin-top: 20px"
        ></ion-icon>
      </ion-item>
    </div>

    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
          class="error-msg"
          v-if="vv?.password?.$errors[0]?.$message === 'Value is required'"
        >
          Contraseña requerida
        </p>
      </div>
    </div>

    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <p
          class="error-msg"
          v-if="vv?.password?.$errors[0]?.$message === 'This field should be at least 8 long'"
        >
          Debe tener un mínimo de 8 caracteres.
        </p>
      </div>
    </div>
      
<!--    <div class="centerCenter" style="margin-top: 15px">-->
<!--      <ion-item mode="md">-->
<!--        <ion-label position="floating" class="labelText"-->
<!--          >Confirmar nueva contraseña</ion-label-->
<!--        >-->
<!--        <ion-input-->
<!--          :type="confirmPasswordFieldType"-->
<!--          name="repeatPassword"-->
<!--          v-model="vv.repeatPassword.$model"-->
<!--        />-->
<!--        <ion-icon-->
<!--          slot="end"-->
<!--          :icon="confirmPasswordEyeStyle"-->
<!--          @click="switchConfirmPasswordVisibility"-->
<!--          style="margin-top: 20px"-->
<!--        ></ion-icon>-->
<!--      </ion-item>-->
<!--    </div>-->

<!--    <div class="centerCenter">-->
<!--      <div class="contenedor" style="width: 100%; justify-content: left;">-->
<!--        <p-->
<!--          class="error-msg"-->
<!--          v-if="vv?.repeatPassword?.$errors[0]?.$message === 'Value is required'"-->
<!--        >-->
<!--          Contraseña requerida-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="centerCenter">-->
<!--      <div class="contenedor" style="width: 100%; justify-content: left;">-->
<!--        <p-->
<!--          class="error-msg"-->
<!--          v-if="vv.repeatPassword.$model !== vv.password.$model"-->
<!--        >-->
<!--          Las contraseñas deben ser iguales-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
      
    <div class="centerCenter">
      <div class="contenedor" style="width: 100%; justify-content: left;">
        <div
          style="
            margin-top: 30px;
            font-family: 'Avenir';
            font-size: 12px;
            text-align: -webkit-left;
          "
        >
          <table>
            <tr>
              <td width="15%">
                <ion-checkbox color="primary" v-on:click="check" mode="md"></ion-checkbox>
              </td>
              <td>
                <span>Acepto terminos y condiciones</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="centerCenter">
      <ion-button
      type="submit"
      class="btn buttonIniciar"
      expand="block"
      fill="solid"
      :disabled="
        termAndCond === false || 
        vv.password.$errors.length != 0 ||
        // vv.password.$model != vv.repeatPassword.$model ||
        // vv.emailAddress.$model != vv.emailConfirm.$model ||
        errorBeforeArroba === true
        "
      >REGISTRARME</ion-button
    >
    <!-- EL siguiente boton existe solo para poder enviar el form haciendo "enter"
        ya que el "ion-button" no puede hacer eso debido a una complicacion con el shadow DOM
        NO BORRAR
    -->
        <button 
          style="display: none" 
          :disabled="
            termAndCond === false || 
            vv.password.$errors.length != 0 ||
            // vv.password.$model != vv.repeatPassword.$model ||
            // vv.emailAddress.$model != vv.emailConfirm.$model ||
            errorBeforeArroba === true
            "
          ></button>
    </div>
    
  </form>
</template>

<script >
import { defineComponent } from "vue";
import { reactive, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import AccountService from "../../../services/account.service";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { IonLabel, IonInput, IonItem, IonButton, IonCheckbox, IonIcon } from "@ionic/vue";
import { Plugins } from '@capacitor/core';
const { FirebaseAnalytics } = Plugins;
import FacebookService from '../../../services/facebook.service';

export default defineComponent({
  name: "RegisterForm",
  components: {
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonCheckbox,
    IonIcon
  },
  data() {
    return {
      termAndCond: false,
      passwordFieldType: "password",
      passwordEyeStyle: eyeOffOutline,
      confirmPasswordFieldType: "password",
      confirmPasswordEyeStyle: eyeOffOutline,
      errorBeforeArroba: false
    };
  },
  setup() {
    const fform = reactive({
      nombre: "",
      emailAddress: "",
      // emailConfirm: "",
      password: "",
      // repeatPassword: "",
    });

    const rules = {
      nombre: { required },
      emailAddress: { required, email },
      // emailConfirm: { required },
      password: { minLength: minLength(8), required },
      // repeatPassword: { required },
    };
    const vv = useVuelidate(rules, {
      nombre: toRef(fform, "nombre"),
      emailAddress: toRef(fform, "emailAddress"),
      // emailConfirm: toRef(fform, "emailConfirm"),
      password: toRef(fform, "password"),
      // repeatPassword: toRef(fform, "repeatPassword"),
    });

    const submitForm = function () {
      vv.value.$touch();
      if (vv.value.$invalid) return;
      AccountService.registerRequest({
        fullName: fform.nombre,
        email: fform.emailAddress,
        password: fform.password,
        // password2: fform.repeatPassword,
      }).then((data) => {
        FirebaseAnalytics.logEvent({
            name: "registro",
            params: {
              fullName: fform.nombre,
              email: fform.emailAddress
            }
        });
        FacebookService.logEvent("registro");
        // creo una variable auxiliar porque no se reconoce la propiedad dataLayer de window al setearse en ejecucion
        // este evento es para Google Analytics version Web
        if (window !== undefined) {
          const windowGA = window;
          if (windowGA.dataLayer !== undefined) {
            windowGA.dataLayer.push({ event: "se_registra" });
          }
        }
        if (data === "success") {
          const myData = [fform.emailAddress, fform.password];
          this.$emit("data-sent", myData);
        }
      });
    };

    return {
      submitForm,
      vv,
    };
  },
  watch: {
    vv: function (val) {
      const beforeArroba = val.emailAddress.$model.split('@').shift();
      if (beforeArroba.length <= 3 && val.emailAddress.$model!= "") {
        this.errorBeforeArroba = true;
      } else {
        this.errorBeforeArroba = false;
      }
    }
  },
  methods: {
    check() {
      this.termAndCond = !this.termAndCond;
    },
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordEyeStyle =
        this.passwordEyeStyle === eyeOffOutline ? eyeOutline : eyeOffOutline;
    },
    switchConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
      this.confirmPasswordEyeStyle =
        this.confirmPasswordEyeStyle === eyeOffOutline ? eyeOutline : eyeOffOutline;
    },
  },
});
</script>

<style scoped>
  ion-item {
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #b4b4b4;
    --background: white;
    margin-top: 10px;
    --highlight-color-focused: #d4692700;
    width: 100%;
    max-width: 700px;
  }

  ion-item.item-has-focus {
    border-bottom: 3px solid #d46927;
  }

  ion-item.item-has-focus > ion-label {
    color: #d46827 !important;
  }
</style>
