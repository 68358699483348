<template>
  <ion-page style="background-color: #fdf2ec;">
    <LoginRegisterNav :valueInicial="'register'"/>
    <ion-content >
      <ion-grid class="grid" v-if="registerSinCorreo">
      <ion-row>
        <ion-col>
          <div class="centerCenter" style="padding: 0px 30px 0px 30px">
          <span class="parrafo">
            Ingresa a través de tu cuenta de Facebook, Google o Apple.
          </span>
        </div>
        </ion-col>
      </ion-row>
      <ion-row class="w-100">
        <ion-col>
          <SocialButtons :is-register="true" />
          <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 0 5px 0;
          "
        >
          <span class="parrafo-small"> O </span>
        </div>
        <div class="centerCenter">
          <ion-button
            class="btn buttonRegister"
            expand="block"
            fill="#ffffff00"
            v-on:click="registerConCorreo"
            >REGISTRARME CON MI CORREO</ion-button
          >
        </div>
   
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 0 5px 0;
          "
        >
          <span class="parrafo-small">
            ¿Ya tienes cuenta?
            <span
              v-on:click="goToLogin"
              style="color: #e33613; font-weight: bold"
              >INICIA SESIÓN</span
            >
          </span>
        </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="grid" v-if="registerConMiCorreo">
      <ion-row class="w-100">
        <ion-col>
          <RegisterForm @data-sent="openRef($event)" />
          <ion-toast
          css-class="success"
          position= 'middle'
          header= '¡Felicitaciones!'
          message="Tu cuenta fue creada exitosamente."
          :is-open="isOpenRef"
          :duration="2000"
          @didDismiss="afterToast()"
        >
        </ion-toast>
         <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px 0 30px 0;
          "
        >
          <span
            style="text-align:center; font-family:'Avenir; font-size: 14px; font-weight: 500; line-height: 1.5; letter-spacing: 0.75px;"
          >
            O ingresa con tus redes sociales
          </span>
        </div>

        <SocialButtons :is-register="true" :is-small="true"/>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 0 25px 0;
          "
        >
          <span class="parrafo-small">
            ¿Ya tienes cuenta?
            <span
              v-on:click="goToLogin"
              style="color: #e33613; font-weight: bold"
              >INICIA SESIÓN</span
            >
          </span>
        </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="grid" v-if="showConfirm">
      <ion-row>
        <ion-col class="grid__content">
          <h3 class="subtitle-1 ion-text-center">
            Ingresa el codigo de validación enviado a tu Email.
          </h3>
        </ion-col>
      </ion-row>
      <ion-row class="w-100">
        <ion-col>
          <ion-toast
          css-class="success"
          position= 'middle'
          header= '¡Felicitaciones!'
          message="Tu cuenta fue validada exitosamente."
          :is-open="isOpenRef2"
          :duration="2000"
          @didDismiss="afterToast2()"
          >
          </ion-toast>
          <ValidationCode
            @enable-button="enableB($event)"
            @disable-button="fourDigit = false"
            ref="validation"
          />
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 30px 0 50px 0;
            "
          >
            <span class="parrafo-small">
              No recibí el codigo
              <span
                style="color: #e33613; font-weight: bold"
                v-on:click="reenviar"
                >REENVIAR</span
              >
            </span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="grid__actions">
        <ion-col>
          <div class="centerCenter">
            <ion-button
            class="btn buttonIniciar"
            expand="block"
            fill="solid"
            @click="sendData"
            :disabled="fourDigit === false"
            >ENVIAR</ion-button
          >
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <span class="parrafo-small" 
            style="color: #e33613; font-weight: bold"
            v-on:click="volverregistro">
            VOLVER AL REGISTRO
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
    </ion-content>
    
  
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import router from "../../router/index";
import {
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonToast,
  IonContent
} from "@ionic/vue";
import ValidationCode from "../../components/ValidationCode";
import AccountService from "../../services/account.service";
import LoginRegisterNav from "./components/LoginRegisterNav";
import SocialButtons from "./components/SocialButtons";
import RegisterForm from "./components/RegisterForm";
import { Plugins } from '@capacitor/core';

export default defineComponent({
  name: "Register",
  components: {
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    ValidationCode,
    IonToast,
    LoginRegisterNav,
    SocialButtons,
    RegisterForm,
    IonContent
  },     
  data() {
    return {
      registerConMiCorreo: false,
      registerSinCorreo: true,
      showConfirm: false,
      isOpenRef: false,
      isOpenRef2: false,
      fourDigit: false,
      myCode: "",
      email: "",
      password: ""
    };
  },
  methods: {
    goToLogin() {
      router.replace("login");
    },
    login() {
      AccountService.loginRequest({ 
        email: this.email,
        password: this.password  
      }).then((status) => {
        router.replace("/onboarding/initial");
      })
    },
    sendData() {
      AccountService.registerActivate({
        email: this.email,
        token: this.myCode,
      }).then((data) => {
        if (data === "success") {
          this.isOpenRef2 = true;
        }
      });
    },
    registerConCorreo() {
      this.registerConMiCorreo = true;
      this.registerSinCorreo = false;
    },
    afterToast() {
      this.isOpenRef = false;
      this.registerConMiCorreo = false;
      this.registerSinCorreo = false;
      // router.replace("/login");
      this.login()
      //No se va a pedir más token en el registro
      // this.showConfirm = true;
    },
    afterToast2() {
      this.isOpenRef2 = false;
      console.log(this.email, this.password);
      this.login();
    },
    enableB(code) {
      this.fourDigit = true;
      this.myCode = code;
    },
    openRef(userData) {
      this.email = userData[0];
      this.password = userData[1];
      this.isOpenRef = true;
    },
    resendRegisterToken() {
      AccountService.resendRegisterToken({
        email: this.email,
      })
    },
    reenviar() {
      this.resendRegisterToken();
      this.$refs.validation.reenviar();
    },
    volverregistro() {
      this.registerSinCorreo = true;
      this.showConfirm = false;
    }    
  },
  async mounted() {
    /**
     * En registro no se va a pedir token
     * const { Storage } = Plugins

     const pend = await Storage.get({ key: 'pending' });
     if (pend.value) {
      const pendiente = JSON.parse(pend.value);
      console.log('Pendiente: ', pendiente)
      this.registerSinCorreo = !pendiente.ispending;
      this.showConfirm = pendiente.ispending;
      this.email = pendiente.email;
    }
     */

  },  
});
</script>
<style lang="scss" scoped>

ion-segment-button {
  --color-checked: var(
    --ion-toolbar-background,
    var(--ion-background-color, #fff)
  );
  --color: var(--ion-toolbar-background, var(--ion-background-color, #fff));
  font-weight: bold;
  font-family: "Avenir";
}
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 0px 0px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  &__actions {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
  }
}

.label {
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }

  h5 {
    margin: 0;
  }
}

ion-item {
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #b4b4b4;
  --background: white;
  margin-top: 10px;
  --highlight-color-focused: #d4692700;
}

ion-item.item-has-focus {
  border-bottom: 3px solid #d46927;
}

ion-item.item-has-focus > ion-label {
  color: #d46827;
}

ion-content {
  --background: #fdf2ec;
}

.buttonRegister {
  margin-top: 20px;
  font-weight: 900;
  font-family: "Avenir";
  width: 100%;
  color: #E33613;
  border-radius: 5px;
  border: 2px solid #e33613;
}

@media (min-width: 720px) {
    .buttonRegister {
      width: 700px;
    }
  }

</style>
